$font-family-sans-serif: 'Montserrat', 'Titillium Web', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, 'Times New Roman', Times, serif;
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;
$font-family-visitmodena: $font-family-sans-serif;
$text-color: rgba(0, 0, 0, 0.87);

$brand-dark: #4d0000;
$brand-light: #fff;

$header-height: 71px;

$font-size-base: 16px;

$primaryColor: #aa2129;
$greyLight: #5c5c5c;

$link-color: $primaryColor;
$link-hover-color: #7c080f;

$bold: 700;
