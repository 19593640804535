.book {
    .listing {
        .item {
            .item-title {
                margin: 0;
                h3 {
                    margin: 1em 0;
                }
            }
        }
        .item.event-item {
            flex-direction: column-reverse;
            .category-wrapper {
                bottom: 0;
                top: unset;
            }
            .item-info {
                top: 0;
                bottom: unset;
            }
        }
    }
}
