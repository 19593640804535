.error-wrapper {
    margin: 1.5rem 0;

    .ui.icon {
        @media (max-width: 480px) {
            i {
                font-size: 2.5rem !important;
                width: 2.5rem !important;
            }
        }
    }

    pre {
        font-size: 0.85rem;
        margin: 0;
        white-space: pre-wrap;
    }
}
