@import '../../../variables';

//event item
.event-item.item {
    .category-wrapper {
        top: 0;
        bottom: unset;
    }

    .item-info {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 100%;
        color: #fff;
        font-weight: $bold;

        p {
            padding: 0.5em 1em;
            margin: 0;
            font-size: 18px;
            text-align: left;
        }
    }

    .item-title {
        h3 {
            margin: 1em 0;
        }

        .item-info {
            position: unset;
        }
    }

    figure {
        position: relative;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            content: '';
            transition: background-color 0.3s ease;
        }
    }

    .item-content {
        a {
            &:hover,
            &:focus {
                figure {
                    &::after {
                        background-color: rgba(0, 0, 0, 0.4);
                    }
                }
            }
        }
    }
}

.listing-container {
    margin-bottom: 3em;
}

// Listing
.listing {
    margin-bottom: 1em;

    .item {
        display: flex;
        flex-direction: column;

        h3 {
            margin: 0 auto;
            font-weight: $bold;
            text-align: center;
        }

        figure {
            display: flex;
            overflow: hidden;
            width: 100%;
            height: 190px;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            img {
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
                object-position: center;
                transform: scale(1);
                transition: transform 0.5s ease-in;
            }
        }

        .item-title {
            a {
                display: inline-block;
                width: 100%;
                text-align: center;
                text-decoration: none;
                color: $text-color;

                &:hover,
                &:focus {
                    h3 {
                        color: $primaryColor;
                    }
                }
            }
        }

        .item-description {
            font-size: 18px;
            text-align: center;
        }

        .item-border {
            width: 80%;
            height: 2px;
            margin: 1rem auto;
            background: $primaryColor;
        }

        .item-content {
            position: relative;

            a {
                display: block;
                text-align: center;
                text-decoration: none;

                &:hover,
                &:focus {
                    figure {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }

        .category-wrapper {
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            width: 100%;

            .category-container {
                display: flex;
                width: 100%;
                padding: 0 1.5em;
                text-align: left;

                .category-label {
                    display: inline-block;
                    padding: 0.5rem 1rem;
                    background: #fff;
                    color: $greyLight;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 19px;
                    text-transform: capitalize;
                }
            }
        }
    }

    .default-item {
        .item-title {
            margin-bottom: 1em;
        }
    }
}

.linkMore {
    text-align: right;

    a {
        &:after {
            content: '';
            border: solid $link-color;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            margin-left: 0.5rem;
            margin-bottom: 0.1rem;
        }

        &:hover,
        &:active {
            &:after {
                border-color: $link-hover-color;
            }
        }
    }
}
//pagination
.pagination-container {
    text-align: center;
    margin: 1em 0 2em 0;
}

//no results
.no-results {
    text-align: center;
    background-color: #efefef;
    padding: 2em;
    margin-bottom: 1em;
    border-radius: 0.5em;
}
