@import '../../variables';

.bm-burger-button,
.bm-cross-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0.5rem;

    i.icon {
        font-size: 1.5rem;
        margin: 0;
    }
}

.bm-menu-wrap {
    background: $brand-dark;
    color: #fff;
    padding: 4rem 2rem;
    top: 0;
    left: 0;

    .bm-cross-button {
        top: 1.25rem !important;
        right: 1.25rem !important;
        width: 2.5rem !important;
        height: 2.5rem !important ;

        &:hover {
            .icon {
                color: #fff;
            }
        }
    }
}

.bm-overlay {
    top: 0;
    left: 0;
}

.bm-item-list {
    text-align: center;
    font-size: 1.5rem;
}

.bm-item {
    padding: 1.5rem 0;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;

    &:hover,
    &:focus {
        color: #eee;
        text-decoration: underline;
    }
}
