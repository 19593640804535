@import '../../_variables';

.loading-wrapper {
    text-align: center;
    padding: 2.5rem 0;

    p {
        color: $brand-dark;
        margin: 1rem 0 0;
    }

    .sk-spinner {
        margin: auto;
        color: $brand-dark;
    }
}
