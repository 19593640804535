@import '../../../variables';
.ui.stackable.grid.visit-list {
    margin-top: 2em;
    margin-bottom: 2em;

    > .row:nth-child(2n + 1) {
        background-color: #e9e9e9;
    }

    > .row {
        h3 {
            text-transform: uppercase;
            font-weight: bold;
            color: $brand-dark;
            border-bottom: 3px solid $primaryColor;
            padding-bottom: 0.4rem;
            margin-bottom: 1em;
            &:after {
                content: '';
                border: solid $brand-dark;
                border-width: 0 3px 3px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                margin-left: 0.3rem;
                margin-bottom: 0.1rem;
            }

            &:hover,
            &:active {
                &:after {
                    border-color: $link-hover-color;
                }
            }
        }
    }
}
