@import '../../variables';

#homepage-view {
    .homepage-links {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                text-align: center;

                a {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    min-height: 200px;
                    padding: 1.5rem 10%;
                    color: #fff;
                    margin: auto;
                    position: relative;

                    h2 {
                        font-size: 2rem;
                        line-height: 2.5rem;
                        z-index: 1;
                        font-weight: 900;
                        text-transform: uppercase;
                        margin: 0;
                    }

                    &::after {
                        position: absolute;
                        content: '';
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.6);
                    }

                    &:hover,
                    &:focus {
                        box-shadow: 0 0 0.5rem rgba(255, 255, 255, 0.8);

                        .link-title {
                            text-decoration: underline;
                        }
                    }

                    &:focus {
                        outline: 0;
                    }
                }

                .link-description {
                    font-size: 1.25rem;
                    padding: 1.5rem 1rem;
                }
            }
        }
    }
}

@mixin hp-sections-backgrounds($format: png) {
    #homepage-view {
        .homepage-links {
            ul {
                li {
                    @each $section in visita, partecipa, prenota {
                        &##{$section} {
                            a {
                                background-image: url('/images/#{$section}/background@1080.#{$format}');

                                @each $width in 720, 480, 360 {
                                    @media (max-width: #{$width}px) {
                                        background-image: url('/images/#{$section}/background@#{$width}.#{$format}');
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

body {
    &.webp {
        @include hp-sections-backgrounds(webp);
    }

    &.no-webp {
        @include hp-sections-backgrounds();
    }
}
