@import '../../variables';

.scroll-to-top {
    position: fixed;
    bottom: -100px;
    right: 20px;
    background: black;
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    text-decoration: none;
    border-radius: 35px;
    transition: all 0.2s linear;
    z-index: 1000;
    cursor: pointer;
    border: 2px solid transparent;

    i {
        position: static;
        width: 100% !important;
        height: 100% !important;
        display: flex !important;
        justify-content: center;
        align-items: center;

        &::before {
            color: #fff;
            margin: 0;
            font-size: 25px;
            transition: all 0.3s ease;
            position: relative;
            top: 0;
        }
    }

    &.scroll-button-shown {
        bottom: 20px;
    }

    &:focus {
        background: rgba(0, 0, 0, 0.9);
        outline: 0;
        border-color: $brand-light;
    }

    &:active {
        background: rgba(0, 0, 0, 0.9);

        i:before {
            position: relative;
            top: -10px;
        }
    }
}
