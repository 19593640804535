.enti-strutture-view {
    #content {
        .item-text {
            margin: 2rem 0 0;

            p {
                margin-bottom: 0.5rem;
            }

            img.first-image-floating {
                width: 360px;
                max-width: 100%;
                margin: 0 0 1.5rem 1.5rem;
                float: right;

                @media (max-width: 600px) {
                    width: 100%;
                    margin: 0 auto 2.5rem;
                    float: none;
                }
            }

            table {
                border-spacing: 0;
                border-collapse: collapse;

                td,
                th {
                    padding: 0.5em 1em;
                    vertical-align: top;
                    border: 1px solid #ddd;
                }

                th {
                    text-align: left;
                }
            }
        }

        figure.leadImage {
            margin: 0 0 2rem;

            @media (max-width: 767px) {
                margin-left: -1em;
                margin-right: -1em;
            }

            img {
                width: 100%;
            }
        }
    }

    .images-wrapper {
        figure {
            margin: 0;

            img {
                margin: auto;
            }
        }
    }

    table.ui.info-table {
        margin-top: 2.5rem;
    }
}
