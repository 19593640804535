@import '../../../variables';

.listing-container {
    margin-bottom: 3em;

    h2 {
        text-align: center;
        text-transform: uppercase;
    }
    .linkMore {
        margin-top: 1em;
    }
}
