@import '../../variables';

body {
    padding-top: $header-height !important;
}

header {
    padding: 1rem 0;
    background: $brand-dark;
    height: $header-height;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;

    #header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $brand-light;

        .header-content {
            a {
                display: block;
            }

            h1.ui.header {
                color: $brand-light;
                margin-top: 0.25rem !important;
                font-family: $font-family-visitmodena;
                font-weight: 900;
                text-transform: uppercase;
                font-size: 2rem;

                @media (max-width: 400px) {
                    font-size: 6vw;
                }
            }
        }

        .ui.dropdown {
            font-size: 1.25rem;

            i.icon {
                margin-left: 0.5rem;
            }

            .menu {
                background: $brand-dark;

                .item {
                    padding: 0 !important;

                    a {
                        display: block;
                        padding: 0.78571429rem 1.14285714rem !important;
                        color: $brand-light;
                    }

                    &.active {
                        a {
                            font-weight: 700;
                            color: $brand-light;
                            position: relative;

                            &::after {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background: rgba(0, 0, 0, 0.2);
                                z-index: 1000;
                            }
                        }
                    }
                }
            }

            .text,
            a {
                user-select: none;
            }
        }
    }
}
