.install-pwa-modal {
    text-align: center;
}

.install-pwa-modal .close.icon {
    top: -0.3rem !important;
    right: -0.5rem !important;
}

.install-pwa-modal figure.app-logo img {
    display: inline-block;
    border-radius: 28px;
    box-shadow: 0 0 5px #ccc;
}

.install-pwa-modal .install-pwa-hint {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 3em;
    margin-bottom: 2em;
}

.install-pwa-modal .install-pwa-hint img {
    display: inline-block;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}
.install-pwa-modal .install-pwa-hint p {
    margin: 0;
}
