@import '../../variables';

.ui.inverted.grey.segment {
    background-color: #313131 !important;
    color: #fff !important;
}

.ui.segment.footer {
    .iat {
        h3,
        h3 * {
            font-weight: $bold !important;
            font-size: 1.1em;
        }

        .bold,
        .bold * {
            font-weight: $bold;
        }

        p {
            margin-bottom: 1em;
        }
    }

    .icon,
    a .icon,
    a.item .icon {
        color: #fff;
    }

    a:hover .icon,
    a:focus .icon,
    a.item:hover .icon,
    a.item:focus .icon {
        color: rgba(255, 255, 255, 0.6);
    }

    .ui.list .list > a.item:hover,
    .ui.list > a.item:hover,
    .ui.list .list > a.item:focus,
    .ui.list > a.item:focus {
        color: rgba(255, 255, 255, 0.6);
    }

    .ui.list.socials {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            text-decoration: none;
        }
    }

    * :not(.icon),
    .ui.form {
        font-size: 18px;
        font-weight: 300;

        input {
            height: 40px;
            border: 1px solid #979797;
            color: #fff;

            &::placeholder {
                color: rgba(255, 255, 255, 0.7);
            }
        }

        button {
            height: 40px;
            padding-top: 0;
            padding-bottom: 0;
            text-transform: uppercase;

            @media (max-width: 767px) {
                margin-top: 0.5em;
            }
        }
    }

    @media (max-width: 991px) {
        .tablet-no-border {
            &,
            &.row,
            &.column {
                border: none !important;
            }
        }

        .tablet-border-bottom {
            &,
            &.column,
            &.column + .column {
                border-top: none;
                border-bottom: 1px solid #979797;
            }
        }
    }

    .ui.celled.internally.grid {
        .row,
        .column,
        & {
            box-shadow: none;
        }

        @media (min-width: 992px) {
            > .column:not(:last-child) {
                border-right: 1px solid grey;
            }
        }
    }

    .ui.celled.grid {
        &.loghi {
            padding-bottom: 1em;
            border-bottom: 1px solid grey;

            > .row {
                &:not(:last-child) {
                    border: none;
                }

                img {
                    max-height: 85px;
                    margin-right: auto;
                    margin-left: auto;
                }
            }
        }

        > .column:not(.row) {
            padding: 0 1.5em;
        }

        @media (min-width: 991px) {
            .row.fixed-height {
                height: 140px;
            }
        }
    }

    @media (min-width: 991px) {
        .padding-top {
            padding-top: 1em;
        }
    }

    .icon-row {
        padding-top: 1em;
    }

    .label {
        margin-bottom: 0.5em;

        .icon {
            margin-right: 0.5em;
        }
    }

    .footer-links {
        padding: 1em 1em 2em !important;
        border-bottom: 1px solid grey;

        a {
            color: #fff;
            display: block;
            margin: 0.5em 0;
        }
    }

    .infos {
        padding-top: 0.5em;
        font-size: 0.7em;
    }

    .ui.grid .row .column.social-links {
        padding: 1em 1em 2em !important;
        border-bottom: 1px solid grey;

        .socials {
            padding-left: 1em;
            padding-right: 1em;
        }
    }

    .ui.grid .row div.column.aligned.wide.language {
        line-height: 26px;
        text-transform: uppercase;
        padding-top: 2em !important;
        padding-bottom: 2em !important;
        border-bottom: 0 !important;
        color: #fff;

        img {
            display: inline-block;
            width: 1.7rem;
            margin-left: 1em;
        }

        text-align: center !important;
    }

    .brand {
        padding-top: 2em;
        padding-bottom: 1em;
        color: #fff;
        text-align: left;

        .bold {
            font-weight: 900;
        }
    }

    .ui.grid > .row > .column.left.aligned {
        text-align: center;
    }

    .ui.stackable.celled.grid.reversed > .column:not(.row) {
        border-top: none;
    }

    &.secondary {
        margin-top: -1px;
        background-color: #313131;

        .ui.stackable.grid {
            > .row {
                > .column,
                .brand {
                    padding-right: 1em !important;
                    padding-left: 1em !important;
                }
            }
        }
    }
}
