@import 'variables';
// @import './visitmodena-font/font';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&display=swap');

html,
body {
    height: 100%;
}

html {
    font-size: 14px;
}

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 0;
    overflow-x: initial;
    font-family: $font-family-sans-serif;
    background: #ffffff;
    font-size: $font-size-base;
    color: $text-color;
}

* {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
    font-family: $font-family-sans-serif;
    margin: 3rem 0 2rem;
    font-weight: 900;

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    font-size: 3rem;
    border-bottom: 5px solid $primaryColor;
    text-align: center;
}

h2 {
    font-size: 2.267rem;
}

a {
    text-decoration: none;
    color: $link-color;

    &:hover {
        color: $link-hover-color;
    }
}

p {
    margin: 0 0 1rem;
    line-height: 1.4285em;

    &:last-child {
        margin-bottom: 0;
    }
}

#content {
    margin: 1rem 0;

    table.ui.celled {
        @media (max-width: 767px) {
            tr {
                padding-top: 0 !important;
                padding-bottom: 0 !important;

                td {
                    padding-top: 0.75rem !important;
                    padding-bottom: 0.75rem !important;
                }
            }
        }
    }
}

.ui.header {
    margin-top: 0.5rem !important;
    font-family: $font-family-sans-serif;
}

.ui.breadcrumb {
    padding: 0.75rem 0;
    margin: 0.5rem 0 !important;

    a {
        color: #41433d !important;
    }
}

.ui.items {
    .item {
        .content {
            align-self: center;
        }
    }
}
