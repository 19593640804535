.item-view {
    padding-bottom: 2.5rem;

    span.description {
        display: block;
        border-left: 5px solid #ccc;
        padding: 0.25rem 0 0.25rem 1rem;
        margin: 0.5rem 0;
    }
}
