.base-view {
    .image-and-text {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        figure {
            margin: 1.5rem 0 2.5rem;
            width: 40%;
            max-width: 30rem;
            max-height: 20rem;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        }

        @media (max-width: 768px) {
            figure {
                width: auto;
                max-width: 100%;
            }
        }
    }
}
